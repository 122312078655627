@use '../../../styles/_global-variables.scss' as global;

.errorContent {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center, top;
    display: table;
    width: 100%;
    height: 100%;
}

.errorLayer {
    position: absolute;
    width: 100%;
    aspect-ratio: 16/9;
    top: 0px;
    left: 0px;
    background: linear-gradient(0deg, rgba(34, 34, 34, 0.8) 0%, rgba(17, 17, 17, 0.8) 100%);
    z-index: 3;
    .errorMsg {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        min-width: 0px;
        width: 92%;
        height: auto;
        margin: auto;
        position: absolute;
        opacity: 0.8;
        display: flex;
        justify-content: center;
        align-items: center;
        .errorText {
            margin: 0 auto;
            background-color: var(--clr_portal_100);
            color: var(--clr_trans_0);
            padding: 8px 16px 5px;
            display: inline-block;
            margin-bottom: 15px;
            .text {
                color: var(--clr_trans_0);
                font-size: var(--body_m);
                font-weight: 500;
                text-transform: uppercase;
                display: inline;
                margin-left: 15px;
            }
        }
        .geoBlocked {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            font-size: var(--headline_s);
            font-weight: 300;
            margin: 0 40px;
            @media #{global.$VP_MOBILE_AND_TP} {
                font-size: var(--body_m);
                gap: 12px;
                .icoGeoBlocked {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

//OCULTEM ICONES QUE NO S'HAN DE MOSTRAR EN MÒBIL
:global(html.mobile) .rmplayer {
    :global {
        .jw-icon-popup {
            display: none;
        }
    }
}

.rmplayer {
    :global {
        .videowrapper > div {
            aspect-ratio: 16/9;
            height: 100% !important;
        }
        .jw-display-icon-container.jw-reset .jw-icon {
            width: 38px;
            height: 38px;
            line-height: 38px;
        }
        .jw-icon-forward .jw-icon {
            width: 32px;
            height: 32px;
            min-height: auto;
        }
        .jw-icon-inici .jw-icon {
            width: 36px;
            height: 36px;
            min-height: auto;
        }
        .jw-icon-directe .jw-icon {
            width: 36px;
            height: 36px;
            min-height: auto;
        }

        .jw-display-controls {
            .jw-display-icon-rewind,
            .jw-display-icon-next {
                display: none;
            }
        }
        .jw-controlbar .jw-icon-next {
            display: none;
        }

        .jwplayer.jw-skin-ccma.jw-state-idle .jw-svg-icon-play,
        .jwplayer.jw-skin-ccma.jw-state-complete .jw-svg-icon-play,
        .jwplayer.jw-skin-ccma.jw-state-paused .jw-display .jw-svg-icon-play,
        .jwplayer.jw-skin-ccma.jw-state-paused .jw-icon-playback .jw-svg-icon-play,
        .jwplayer.jw-skin-ccma .jw-icon-volume .jw-svg-icon,
        .jwplayer.jw-skin-ccma .jw-icon-rewind .jw-svg-icon-rewind,
        .jw-svg-icon-settings,
        .jw-svg-icon-more-videos,
        .jw-icon-pip .jw-svg-icon-pip-on,
        .jw-icon-pip.jw-off .jw-svg-icon-pip-off,
        .jw-icon-fullscreen .jw-svg-icon-fullscreen-on,
        .jw-icon-fullscreen.jw-off .jw-svg-icon-fullscreen-off,
        .jw-icon-cc .jw-svg-icon-cc-on,
        .jw-icon-cc.jw-off .jw-svg-icon-cc-off,
        .jw-settings-captions .jw-svg-icon,
        .jw-settings-quality .jw-svg-icon,
        .jwplayer.jw-state-playing .jw-display .jw-icon-display .jw-svg-icon-pause,
        .jwplayer.jw-state-playing .jw-icon-playback .jw-svg-icon-pause {
            display: none;
        }

        .jwplayer.jw-skin-ccma .jw-icon-settings,
        .jwplayer.jw-skin-ccma .jw-icon-cast,
        .jwplayer.jw-skin-ccma .jw-related-btn,
        .jwplayer.jw-skin-ccma .jw-icon-pip,
        .jwplayer.jw-skin-ccma .jw-icon-fullscreen,
        .jwplayer.jw-skin-ccma .jw-icon-cc,
        .jw-settings-captions,
        .jw-settings-quality {
            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 32px;
                height: 32px;
                opacity: 1;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
        .jw-settings-quality:before {
            background-image: url(/_resources/icones/blanc/filtres.svg?v=1.2502.4);
        }
        .jw-settings-captions:before {
            background-image: url(/_resources/icones/blanc/subtitols.svg?v=1.2502.4);
        }
        .jwplayer.jw-skin-ccma .jw-icon-cc {
            &:before {
                background-image: url(/_resources/icones/blanc/subtitols_on.svg?v=1.2502.4);
            }
            &.jw-off:before {
                background-image: url(/_resources/icones/blanc/subtitols.svg?v=1.2502.4);
            }
        }
        .jwplayer.jw-skin-ccma .jw-icon-pip {
            &:before {
                background-image: url(/_resources/icones/blanc/pantalla_min.svg?v=1.2502.4);
            }
            &.jw-off:before {
                background-image: url(/_resources/icones/blanc/pantalla_max.svg?v=1.2502.4);
            }
        }
        .jwplayer.jw-skin-ccma .jw-icon-fullscreen {
            &:before {
                background-image: url(/_resources/icones/blanc/pantalla_expand.svg?v=1.2502.4);
            }
            &.jw-off:before {
                background-image: url(/_resources/icones/blanc/pantalla_contract.svg?v=1.2502.4);
            }
        }
        .jwplayer.jw-skin-ccma .jw-related-btn:before {
            background-image: url(/_resources/icones/blanc/explora.svg?v=1.2502.4);
        }
        .jwplayer.jw-skin-ccma .jw-icon-settings:before {
            background-image: url(/_resources/icones/blanc/opcions.svg?v=1.2502.4);
        }

        .jwplayer.jw-skin-ccma .jw-icon-cast {
            * {
                opacity: 0;
            }
            &:before {
                background-image: url(/_resources/icones/blanc/chromecast.svg?v=1.2502.4);
            }
        }

        .jwplayer.jw-skin-ccma .jw-icon-forward .jw-icon {
            background-size: cover;
        }

        .jwplayer.jw-skin-ccma.jw-state-paused .jw-icon-playback:before,
        .jwplayer.jw-skin-ccma.jw-state-complete .jw-icon-playback:before,
        .jwplayer.jw-skin-ccma.jw-state-idle .jw-icon-display:before,
        .jwplayer.jw-skin-ccma.jw-state-paused .jw-icon-display:before {
            background-image: url(/_resources/icones/blanc/play.svg?v=1.2502.4);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 80%;
            height: 80%;
            content: '';
        }
        .jwplayer.jw-skin-ccma.jw-state-playing .jw-icon-display:before {
            background-image: url(/_resources/icones/blanc/pause_ple.svg?v=1.2502.4);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 80%;
            height: 80%;
            content: '';
        }
        .jwplayer.jw-skin-ccma .jw-icon-playback {
            .jw-svg-icon,
            svg {
                display: none;
            }
        }
        .jwplayer.jw-skin-ccma.jw-state-playing .jw-icon-playback {
            &:before {
                background-image: url(/_resources/icones/blanc/pause_ple.svg?v=1.2502.4);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 28px;
                height: 28px;
                content: '';
                opacity: 0.8;
            }
        }
        .jwplayer.jwplayer.jw-skin-ccma .jw-related-item-container .jw-related-item-play {
            .jw-svg-icon {
                display: none;
            }
            &:before {
                background-image: url(/_resources/icones/blanc/play.svg?v=1.2502.4);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 80%;
                height: 80%;
                content: '';
            }
        }
        .jwplayer.jw-skin-ccma .jw-icon {
            @media (hover) {
                opacity: 0.8;
                .jw-icon {
                    opacity: 1;
                }
                &:hover {
                    opacity: 1;
                }
            }
        }

        .jwplayer .jw-skip.jw-skippable .jw-skip-icon {
            display: inline-flex;
        }
        .jwplayer.jw-skin-ccma .jw-icon-volume {
            color: transparent !important;
            &:before {
                content: '';
                width: 32px;
                height: 32px;
                opacity: 1;
                background: url(/_resources/icones/blanc/volum_minim.svg?v=1.2502.4) no-repeat center center;
                background-size: contain;
            }
            &.jw-off:before {
                background-image: url(/_resources/icones/blanc/novolum.svg?v=1.2502.4);
            }
            &.jw-full:before {
                background-image: url(/_resources/icones/blanc/volum.svg?v=1.2502.4);
            }
        }
        .jwplayer.jw-skin-ccma .jw-icon-rewind:before {
            content: '';
            width: 32px;
            height: 32px;
            opacity: 1;
            background: url(/_resources/icones/blanc/10smenys.svg?v=1.2502.4) no-repeat center center;
            background-size: contain;
        }

        .jwplayer.jw-skin-ccma.jw-state-paused .jw-icon-playback:before {
            width: 36px;
            height: 36px;
        }

        .jw-display-icon-container {
            margin: 0 10px;
            padding: 5px;
            background-color: var(--clr_portal_100);
            border-radius: 50%;
            .jw-icon {
                opacity: 1 !important;
            }
            &:hover {
                background-color: var(--clr_portal_80);
            }
        }

        .jwplayer.jw-skin-ccma.jw-state-idle .jw-display-icon-container:hover .jw-icon-display:before,
        .jwplayer.jw-skin-ccma.jw-state-paused .jw-icon-playback:hover:before {
            opacity: 1;
        }

        .jwplayer.jw-skin-ccma .jw-rightclick {
            display: none !important;
        }

        .jwplayer.jw-flag-fullscreen:not(.jw-flag-overlay-open-related) {
            &:not(.jw-flag-user-inactive),
            &.jw-state-paused {
                .fullscreenrow > div {
                    opacity: 1;
                    pointer-events: unset;
                }
            }
        }

        .bitmovinplayer-container
            .bmpui-fullscreen.bmpui-controls-shown
            .bmpui-container-wrapper
            .fullscreenrow.fullscreenrow
            > div {
            opacity: 1;
            pointer-events: unset;
        }

        .jwplayer.jwplayer .jw-controlbar {
            .jw-progress,
            .jw-old-progress {
                background-color: var(--clr_portal_100);
                height: 7px;
                border-radius: 4px;
            }
            .jw-tooltip {
                border-radius: 8px;
                transform: translate(-50%, -7px);
                &.jw-open {
                    transform: translate(-50%, -7px);
                    opacity: 1;
                    &.jw-tooltip-chromecast {
                        opacity: 1;
                        .jw-text {
                            opacity: 1;
                        }
                    }
                }
                .jw-text {
                    font-size: var(--body_s);
                    border-radius: 8px;
                    min-height: auto;
                }
            }
            .jw-tooltip-time {
                border-radius: 8px;
                transform: translate(-50%, 12px);
                &.jw-open {
                    transform: translate(-50%, 12px);
                    opacity: 1;
                }
                .jw-text {
                    font-size: var(--body_xs);
                    border-radius: 8px;
                }
            }
            .jw-time-tip {
                border-radius: 8px;
                .jw-text {
                    line-height: 1;
                    padding-top: 10px;
                }
            }
        }
        .jwplayer .jw-controlbar .jw-icon-playback .jw-tooltip {
            transform: translate(-50%, 0);
            &.jw-open {
                transform: translate(-50%, 0);
            }
        }

        //$VP_TP: '(min-width: 768px) and (max-width: 1023px)';

        //$VP_BEYOND_TP: '(min-width: 1024px)';

        @media #{global.$VP_BEYOND_TP} {
            // .jwplayer.jw-skin-ccma.jw-state-paused .jw-icon-playback:before,
            .jwplayer.jw-skin-ccma.jw-state-idle .jw-icon-display:before {
                width: 60px;
                height: 60px;
            }
        }
    }

    //PLAYER VIDEO

    &.rmpDirecte:not(.rmplayerAudio) {
        :global {
            .jw-text-elapsed,
            .jw-text-duration {
                display: none !important;
            }
            .jw-text-live {
                display: none;
            }
        }
    }

    :global {
        .jwplayer.jw-skin-ccma:not(.jw-flag-media-audio) {
            .jw-controlbar {
                margin-bottom: 10px;
                flex-direction: inherit;
            }
            &.jw-flag-ads {
                .jw-controlbar {
                    top: auto;
                }
                .jw-slider-container {
                    pointer-events: none;
                }
            }
            .jw-slider-volume {
                align-items: flex-start;
                .jw-slider-container {
                    margin-left: 8px;
                }
            }
            .jw-icon-volume .jw-overlay {
                padding-bottom: 0 !important;
            }
            .jw-button-container {
                .jw-icon-playback {
                    order: 1;
                    &:before {
                        transform: scale(1.2);
                    }
                }
                .jw-text-elapsed {
                    order: 2;
                    opacity: 0.8 !important;
                }
                .jw-text-duration {
                    order: 3;
                    width: auto !important;
                    margin-right: 8px;
                    opacity: 0.8 !important;
                }
                .jw-icon-volume {
                    order: 4;
                    flex-grow: 1;
                    justify-content: start;
                    margin-left: 10px;
                    pointer-events: none;
                    .jw-overlay {
                        width: 44px;
                        left: 24px;
                    }
                    &:before {
                        pointer-events: all;
                        min-height: 80px;
                    }
                }
                .jw-icon-cc {
                    order: 5;
                    margin-right: 8px;
                }
                .jw-icon-settings {
                    order: 6;
                    margin-right: 8px;
                }
                .jw-related-btn {
                    order: 7;
                    margin-right: 8px;
                    &:before {
                        transform: scale(0.9);
                    }
                }
                .jw-icon-pip {
                    order: 8;
                    margin-right: 8px;
                }
                .jw-icon-cast {
                    order: 9;
                    margin-right: 8px;
                }
                .jw-icon-fullscreen {
                    order: 10;
                    .jw-tooltip {
                        transform: translate(-8px, -7px);
                    }
                    &:before {
                        transform: scale(0.8);
                    }
                }
            }
            .jw-controlbar {
                top: 0;
                max-height: none;
                align-content: flex-end;
                pointer-events: none !important;
                .jw-slider-time,
                .jw-button-container {
                    pointer-events: all;
                }
            }
            .jw-button-container {
                .jw-icon-rewind {
                    position: absolute;
                    top: 50%;
                    margin-top: -30px;
                    left: 50%;
                    margin-left: -115px;
                    visibility: hidden;
                }
                .jw-icon-forward {
                    position: absolute;
                    top: 50%;
                    margin-top: -30px;
                    right: 50%;
                    margin-right: -115px;
                    background-size: contain;
                    visibility: hidden;
                }
                .jw-icon-inici {
                    position: absolute;
                    top: 50%;
                    margin-top: -30px;
                    right: 50%;
                    margin-right: 120px;
                    background-size: contain;
                    visibility: hidden;
                    .jw-icon {
                        width: 36px;
                        height: 32px;
                        background-size: cover;
                    }
                }
                .jw-icon-directe {
                    position: absolute;
                    top: 50%;
                    margin-top: -30px;
                    right: 50%;
                    margin-right: -190px;
                    background-size: contain;
                    visibility: hidden;
                    .jw-icon {
                        width: 52px;
                        height: 32px;
                        background-size: cover;
                    }
                }
            }
            &.jw-state-playing .jw-button-container {
                .jw-icon-rewind,
                .jw-icon-forward,
                .jw-icon-inici,
                .jw-icon-directe {
                    visibility: visible;
                }
            }
            &.jw-state-paused,
            &.jw-state-playing:not(.jw-flag-user-inactive) {
                .jw-display {
                    display: flex !important;
                }
            }
            &.jw-state-idle .jw-controls {
                background: rgba(17, 17, 17, 0.4);
            }

            &.jw-breakpoint-4 {
                .jw-display-icon-container.jw-reset .jw-icon {
                    width: 54px;
                    height: 54px;
                    line-height: 54px;
                }
            }

            &.jw-breakpoint-4,
            &.jw-breakpoint-3,
            &.jw-breakpoint-2,
            &.jw-breakpoint-1,
            &.jw-breakpoint-0 {
                .jw-button-container {
                    .jw-icon-rewind {
                        position: absolute;
                        top: 50%;
                        margin-top: -12px;
                        left: 50%;
                        margin-left: -70px;
                        display: flex;
                    }
                    .jw-icon-forward {
                        position: absolute;
                        top: 50%;
                        margin-top: -12px;
                        right: 50%;
                        margin-right: -70px;
                        background-size: contain;
                        .jw-icon {
                            background-size: 22px;
                        }
                    }
                    .jw-icon-inici {
                        position: absolute;
                        top: 50%;
                        margin-top: -12px;
                        right: 50%;
                        margin-right: 95px;
                        background-size: contain;
                        .jw-icon {
                            background-size: 28px;
                        }
                    }
                    .jw-icon-directe {
                        position: absolute;
                        top: 50%;
                        margin-top: -12px;
                        right: 50%;
                        margin-right: -130px;
                        width: 36px;
                        background-size: contain;
                        .jw-icon {
                            background-size: 36px;
                        }
                    }
                    .jw-icon-playback {
                        margin-right: 8px;
                    }
                    .jw-icon-cc {
                        margin-right: 2px;
                    }
                    .jw-icon-settings {
                        margin-right: 2px;
                    }
                    .jw-related-btn {
                        margin-right: 2px;
                    }
                    .jw-icon-pip {
                        margin-right: 2px;
                    }
                    .jw-icon-cast {
                        margin-right: 2px;
                    }
                }

                .jw-icon-inline,
                .jw-icon-tooltip {
                    width: 30px;
                    height: 30px;
                    &:before {
                        width: 22px;
                        height: 22px;
                    }
                    &.jw-text-duration:before {
                        height: 13px;
                    }
                }

                .jw-text-live {
                    width: auto;
                    height: auto;
                    padding: 3px;
                    font-size: var(--body_xxs);
                    &:before {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
            &.jw-breakpoint-6,
            &.jw-breakpoint-5 {
                /*.jw-display-icon-container.jw-reset .jw-icon {
                    width: 48px;
                    height: 48px;
                }*/
                .jw-display-icon-container.jw-reset .jw-icon {
                    width: 74px;
                    height: 74px;
                    line-height: 74px;
                }
                .jwplayer.jw-skin-ccma.jw-state-idle .jw-icon-display:before {
                    width: 60px;
                    height: 60px;
                }
                .jw-button-container {
                    .jw-icon-playback {
                        margin-right: 12px;
                    }
                    .jw-icon-rewind {
                        position: absolute;
                        top: 50%;
                        margin-top: -12px;
                        left: 50%;
                        margin-left: -85px;
                    }
                    .jw-icon-forward {
                        position: absolute;
                        top: 50%;
                        margin-top: -12px;
                        right: 50%;
                        margin-right: -85px;
                        background-size: contain;
                    }
                    .jw-icon-inici {
                        position: absolute;
                        top: 50%;
                        margin-top: -12px;
                        left: 50%;
                        margin-left: -150px;
                    }
                    .jw-icon-directe {
                        position: absolute;
                        top: 50%;
                        margin-top: -12px;
                        right: 50%;
                        margin-right: -155px;
                        background-size: contain;
                    }
                }
                .jw-icon-inline,
                .jw-icon-tooltip {
                    width: 36px;
                    height: 36px;
                    &:before {
                        width: 30px;
                        height: 30px;
                    }
                    &.jw-text-duration:before {
                        height: 13px;
                    }
                }
                .jw-text-elapsed,
                .jw-text-duration {
                    font-size: var(--body_m);
                }

                .jw-text-live {
                    width: auto;
                    height: auto;
                    padding: 6px 5px 5px 5px;
                    font-size: var(--body_xxs);
                    &:before {
                        width: 8px;
                        height: 8px;
                        margin-top: -2px;
                    }
                }
            }
            &.jw-breakpoint-1,
            &.jw-breakpoint-0 {
                .jw-text-live {
                    font-size: 9px;
                    padding: 4px 4px 2px 4px;
                    &:before {
                        width: 4px;
                        height: 4px;
                        margin-top: -3px;
                    }
                }
            }
            &.jw-breakpoint-7 {
                .jw-display-icon-container.jw-reset .jw-icon {
                    width: 74px;
                    height: 74px;
                    line-height: 74px;
                }
                .jw-controlbar .jw-progress {
                    height: 10px;
                }
            }
        }
    }
    &.inactiveButtons {
        :global {
            .jwplayer.jwplayer {
                .jw-text-live,
                .jw-icon-directe,
                .jw-icon-forward {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
        }
    }
    &.inactiveStartOverButton {
        :global {
            .jwplayer.jwplayer {
                .jw-icon-inici {
                    pointer-events: none;
                    display: none;
                }
            }
        }
    }
    //PLAYER AUDIO
    &.rmplayerAudio {
        :global {
            .jwplayer.jwplayer {
                overflow: visible;
                position: absolute;
                top: 0;
                &.jw-breakpoint-0,
                &.jw-breakpoint-1,
                &.jw-breakpoint-2 {
                    .jw-text-live {
                        display: none;
                    }
                }
                &.jw-breakpoint-0,
                &.jw-breakpoint-1,
                &.jw-breakpoint-2,
                &.jw-breakpoint-3 {
                    &:not(.jw-flag-ads) .jw-controlbar .jw-button-container {
                        .jw-icon-cast,
                        .jw-icon-airplay {
                            top: calc((-100vw / 16 * 9) + 68px - 208px);
                            right: 0;
                            position: absolute;
                        }
                    }
                }
                &.jw-breakpoint-0,
                &.jw-breakpoint-1 {
                    &:not(.jw-flag-ads) .jw-controlbar .jw-button-container {
                        .jw-icon-rewind {
                            left: calc(50% - 65px);
                        }
                        .jw-icon-forward {
                            right: calc(50% - 65px);
                        }
                        .jw-icon-popup {
                            display: none;
                        }
                    }
                }
                .jw-media {
                    pointer-events: none;
                }
                .jw-text-live.jw-dvr-live {
                    box-shadow: none;
                }
                &.jwplayer.jw-skin-ccma .jw-icon:before {
                    background-size: auto;
                }
                .jw-controlbar .jw-icon-playback {
                    .jw-svg-icon {
                        display: none !important;
                    }
                }
                &.jw-state-idle .jw-controlbar .jw-icon-playback {
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4LjExMDEgMTAuMjE2OEMxOC4wOTY2IDEwLjIwNTMgMTguMDg3NyAxMC4xODgzIDE4LjA3MzIgMTAuMTc2OEMxOC4wNzMyIDEwLjE3NjggMTAuMjIwMSA0LjQyNzQ5IDEwLjIxMjYgNC40Mjc0OUM5Ljg3NDYyIDQuMTYzNDkgOS40NTU4NCA0IDguOTk0MTggNEM3Ljg5MjkgNCA3IDQuODk1NDcgNyA1Ljk5OTk0QzcgNi4wMTY5NCA3LjAwNDQ5IDYuMDMyNDQgNy4wMDQ5OSA2LjA0OTk0QzcuMDA0NDkgNi4wNjQ5NCA3IDYuMDc4OTQgNyA2LjA5MzkzVjE3LjkwNTFDNyAxNy45MjAxIDcuMDA0NDkgMTcuOTM0NiA3LjAwNDk5IDE3Ljk0OTZDNy4wMDQ0OSAxNy45NjY2IDcgMTcuOTgyNiA3IDE4LjAwMDFDNyAxOS4xMDQ1IDcuODkyOSAyMCA4Ljk5NDE4IDIwQzkuNDU3MzMgMjAgOS44NzgxMSAxOS44MzUgMTAuMjE2NiAxOS41Njk1QzEwLjIyNTYgMTkuNTY5NSAxOC4wNjIyIDEzLjYwNDcgMTguMDYyMiAxMy42MDQ3QzE4LjA4MzcgMTMuNTg2MiAxOC4xMDAxIDEzLjU1OTcgMTguMTIwNiAxMy41NDAyQzE4LjY1MSAxMy4xODA3IDE5IDEyLjU3MjIgMTkgMTEuODgyM0MxOSAxMS4xODYzIDE4LjY0NjUgMTAuNTc1MyAxOC4xMTAxIDEwLjIxNjhaIiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPgo=);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    width: 28px;
                    height: 28px;
                    content: '';
                    opacity: 0.8;
                }
                &.jw-flag-small-player:not(.jw-flag-audio-player):not(.jw-flag-ads):not(.jw-state-idle)
                    .jw-controlbar
                    .jw-button-container
                    > .jw-icon-rewind {
                    display: flex;
                }
                &.jw-state-idle {
                    .jw-button-container .jw-icon {
                        display: none !important;
                    }
                }
                &.jw-flag-ads {
                    width: 86% !important;
                    left: 7%;
                    aspect-ratio: 16 / 9;
                    height: auto !important;
                }
                .jw-slider-time {
                    order: 2;
                    z-index: 2;
                }
                .jw-wrapper {
                    background-color: transparent;
                }
                .jw-controls {
                    background-color: transparent;
                    overflow: visible;
                }
                .jw-controls-backdrop {
                    background: none;
                }
                .jw-display {
                    display: none;
                }
                .jw-controlbar {
                    pointer-events: none;
                    & > * {
                        pointer-events: all;
                    }
                }
                .jw-preview {
                    width: calc(50% - 20px);
                    background-size: contain !important;
                    background-color: transparent;
                    background-position: top;
                    aspect-ratio: 16 / 9;
                    height: auto;
                }
                .jw-text-countdown {
                    display: none !important;
                }
                &:not(.jw-flag-ads) .jw-controlbar {
                    margin-bottom: 36px;
                    z-index: 3;
                    display: flex !important;
                    .jw-slider-time {
                        padding: 0;
                        height: 17px;
                        display: flex !important;
                        .jw-slider-container {
                            height: 5px;
                            .jw-rail,
                            .jw-old-rail {
                                background-color: var(--clr_trans_40);
                                height: 4px;
                                border-radius: 2px;
                            }
                        }
                    }
                    .jw-slider-volume {
                        padding: 13px 0 0;
                    }
                    .jw-tooltip {
                        font-size: var(--body_s);
                        .jw-text {
                            padding: 10px;
                        }
                    }
                    .jw-button-container {
                        z-index: 1;
                        height: 44px;
                        .jw-icon {
                            width: 28px;
                            height: 44px;
                            top: -12px;
                            .jw-svg-icon {
                                width: 24px;
                                height: 24px;
                            }
                        }
                        .jw-icon-playback {
                            width: 56px;
                            height: 56px;
                            border-radius: 28px;
                            opacity: 1;
                            position: absolute;
                            left: calc(50% - 28px);
                            top: -18px;
                            background-color: var(--clr_portal_100);
                            background-size: 40px;
                            display: flex !important;
                            &:before {
                                width: 40px;
                                height: 40px;
                                opacity: 1;
                                background-size: contain;
                            }
                            @media (hover) {
                                &:hover {
                                    background-color: var(--clr_portal_140);
                                }
                            }
                        }
                        .jw-icon-pip {
                            display: none;
                        }
                    }
                    .jw-icon-rewind {
                        position: absolute;
                        top: -12px;
                        left: calc(50% - 86px);
                    }
                    .jw-icon-forward {
                        position: absolute;
                        top: -12px;
                        right: calc(50% - 86px);
                        .jw-icon {
                            background-size: auto;
                        }
                    }
                    .jw-icon-fullscreen {
                        display: none;
                    }
                    .jw-icon-popup {
                        order: 1;
                    }
                    .jw-button-container {
                        position: relative;
                        padding: 0;
                        gap: 12px;
                        .jw-text-elapsed {
                            position: absolute;
                            left: calc(50% - 70px);
                            top: calc(100% + 24px);
                            text-align: right;
                            width: 60px;
                            display: block;
                            font-size: var(--body_s) !important;
                        }
                        .jw-text-duration {
                            position: absolute;
                            right: calc(50% - 50px);
                            top: calc(100% + 24px);
                            text-align: left;
                            width: 60px;
                            display: block;
                            font-size: var(--body_s) !important;
                        }
                    }
                }
                @media (max-width: 880px) {
                    .jw-preview {
                        width: 100%;
                        background-size: cover !important;
                        aspect-ratio: 9 / 7;
                        position: relative;
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            background: linear-gradient(
                                    179.49deg,
                                    rgba(0, 0, 0, 0) 65.25%,
                                    rgba(8, 8, 8, 0.6) 82.59%,
                                    #111111 94.13%
                                ),
                                radial-gradient(
                                    289.16% 913.88% at 203.01% 50%,
                                    #111111 0%,
                                    rgba(17, 17, 17, 0) 53.47%,
                                    #111111 100%
                                );
                        }
                    }
                    .jw-controlbar {
                        bottom: -182px;
                        width: calc(100% - 68px);
                        left: 34px;
                    }
                }

                @media #{global.$VP_ALL_MOBILE} {
                    .jw-controlbar {
                        left: 12px;
                        width: auto;
                        right: 12px;
                    }
                }
            }
        }

        &.rmpDirecte {
            :global {
                .jwplayer.jwplayer {
                    .jw-preview {
                        width: 32%;
                        aspect-ratio: 1 / 1;
                        height: auto;
                        background-size: cover !important;
                        background-color: var(--clr_trans_80);
                    }
                    .jw-text-live {
                        position: absolute;
                        left: calc(50% + 106px);
                        text-indent: -999px;
                        min-width: 54px;
                        overflow: hidden;
                        display: block;
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: calc(50% - 14px);
                            bottom: 0;
                            width: 54px;
                            height: 28px;
                            opacity: 1;
                            background: url(/_resources/icones/blanc/torna_directe.svg?v=1.2502.4) no-repeat center
                                center;
                        }
                    }
                    &.jw-breakpoint-0,
                    &.jw-breakpoint-1 {
                        .jw-text-live {
                            left: auto;
                            right: 0;
                            margin-right: 0;
                        }
                    }
                    .jw-text-elapsed,
                    .jw-text-duration {
                        display: none !important;
                    }
                    @media (max-width: 880px) {
                        .jw-preview {
                            width: 52%;
                            left: 24%;
                            &:before {
                                content: none;
                            }
                        }
                        .jw-controlbar {
                            bottom: -158px;
                        }
                    }
                }
                .no-dvr {
                    .jwplayer.jwplayer {
                        .jw-text-live,
                        .jw-icon-forward {
                            display: none;
                        }
                        .jw-slider-time {
                            pointer-events: none;
                            .jw-old-rail {
                                background: var(--clr_portal_100) !important;
                            }
                        }
                    }
                }
            }
        }
        &.embed {
            :global {
                .jwplayer.jwplayer {
                    @media (max-width: 880px) {
                        .jw-controlbar {
                            bottom: 0;
                        }
                    }
                }
            }
        }
        &.embed:not(.rmpDirecte) {
            :global {
                .jwplayer.jwplayer {
                    .jw-preview {
                        width: 100%;
                    }
                }
            }
        }
        &.popup {
            :global {
                .jwplayer.jwplayer {
                    .jw-preview {
                        width: 100%;
                        background-size: cover !important;
                        aspect-ratio: 3 / 2;
                        position: relative;
                        left: 0;
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            background: linear-gradient(
                                    179.49deg,
                                    rgba(0, 0, 0, 0) 61.25%,
                                    rgba(8, 8, 8, 0.6) 69.59%,
                                    #111111 81.13%
                                ),
                                radial-gradient(
                                    289.16% 913.88% at 203.01% 50%,
                                    #111111 0%,
                                    rgba(17, 17, 17, 0) 53.47%,
                                    #111111 100%
                                );
                        }
                    }
                    .jw-icon-popup {
                        display: none;
                    }
                    .jw-controlbar {
                        bottom: 0;
                        width: calc(100% - 32px);
                        left: 16px;
                    }

                    .jw-text-live {
                        left: calc(50% + 90px);
                    }

                    &:not(.jw-flag-ads) .jw-controlbar {
                        .jw-icon-rewind {
                            left: calc(50% - 76px);
                        }
                        .jw-icon-forward {
                            right: calc(50% - 76px);
                        }
                        .jw-button-container .jw-icon-playback {
                            width: 48px;
                            height: 48px;
                            top: -12px;
                            left: calc(50% - 24px);
                            background-size: 34px;
                            &:before {
                                width: 34px;
                                height: 34px;
                            }
                        }
                    }
                }
            }
        }
        .errorContent {
            position: initial;
        }
    }
    &.noticia {
        :global {
            .videowrapper > div {
                aspect-ratio: 16/9;
                height: auto !important;
                @media (max-width: 880px) {
                    aspect-ratio: 9/7;
                }
            }

            .jwplayer.jwplayer {
                position: relative;
                .jw-preview {
                    width: 100%;
                }
                .jw-text-countdown {
                    display: none !important;
                }
                &:not(.jw-flag-ads) .jw-controlbar {
                    padding: 12px;
                    @media (max-width: 880px) {
                        bottom: -18px;
                    }
                    @media #{global.$VP_ALL_MOBILE} {
                        bottom: 0px;
                    }
                }

                @media #{global.$VP_ALL_MOBILE} {
                    .jw-controlbar {
                        left: 12px;
                        width: auto;
                        right: 12px;
                    }
                    &:not(.jw-flag-ads) .jw-controlbar .jw-button-container .jw-icon-playback {
                        width: 48px;
                        height: 48px;
                        top: -12px;
                    }
                }
            }
        }
    }
}

.fullscreenrow {
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    padding: 20px;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;
    .nom,
    .titol {
        color: #fff;
        line-height: 1.5;
        pointer-events: none;
    }
    .nom {
        font-size: 23px;
        font-weight: 300;
        line-height: 141%;
        pointer-events: none;
    }
    .titol {
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 6px;
        pointer-events: none;
    }
    .edat {
        margin-right: 12px;
        pointer-events: none;
    }
    .boto {
        padding: 0 4px;
        height: 20px;
        border: 1px solid #fff;
        border-radius: 4px;
        margin-right: 16px;
        font-size: var(--body_xxs);
        pointer-events: all;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .subinfo {
        display: flex;
        gap: 18px;
        align-items: center;
        padding-bottom: 16px;
        height: 40px;
    }
}

:global(body.webview) .fullscreenrow {
    padding-left: 60px;
}

//ocultem botons en webview
.webview {
    :global {
        .jw-icon-pip {
            display: none;
        }
    }
}

@media #{global.$VP_ALL_MOBILE} {
    .fullscreenrow {
        .nom {
            font-size: var(--body_l);
            line-height: 2;
        }
        .titol {
            font-size: var(--body_m);
            line-height: 1.5;
        }
    }
}

@media (hover: none) {
    .jwplayer .jw-controlbar .jw-icon-playback .jw-tooltip.jw-open {
        display: none;
    }
}
